.Home-section {
  flex:1;
  width: 100%;
  border-bottom: 1px #dcdcdc solid;
  padding: 96px 13.333%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Home-section-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 13.333%;
}

.Home-1-left {
  margin: 30px 0 50px;
  order: 2;
}

.Home-2-right {
  margin-top: 50px;
  order: 1;
}

.Home-section-2 {
  text-align: center;
}

.Home-2-row {
  display: flex;
  flex-wrap: wrap;
  padding: 58px 0% 20px 0%;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  max-width: 900px;

}

.Home-2-row-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex:1;
  margin: 0 5px;
//  margin: 0 4%;
}

.Home-green-accent {
  margin: 10px 0;
}

.Home-section-3 {
  text-align: center;

}

.Home-3-row {
  display: flex;
  flex-direction: row;
  margin-top: 80px;
}

.Home-3-list {
  text-align: left;
  margin-left: 10%;
  
  max-width: 70%;
}

.Home-3-list-item {
  margin-bottom: 35px;
}

.Home-section-4 {
  text-align: center;
  padding-bottom: 40px;

}


.Home-apron-img {
  height: auto;
  width: 100%;
}

.Home-lettuce-img {
  height: auto;
  width: 100%;
}

.Home-circle-img {
  height: auto;
  width: 100%;
  max-width: 160px;
  max-height: 160px;
  margin-bottom: 10px;
  min-width: 74px;

}

.Home-gropod-img {
  height: auto;

  width: 100%;
}

.Home-margin-bottom {
  margin-bottom: 10px;
}

.Home-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
    font-family: $open;
    line-height: 24px;
    color: $content-grey;
    background-color: white;
}

.Home-button {
  margin-Top: 48px;
}

.Home-button-4 {
  margin-bottom: 20px;
}

@media(min-width: 996px) {
  .Home-section-1 {
    flex-direction: row;
  }
  .Home-1-left {
    margin: 60px 0;
    order: 1;
  }
  .Home-2-right {
    padding: 60px 0 60px 30px;
    order: 2;
  }
}

@media(max-width: 675px) {
  .Home-3-row {
    flex-direction: column;
    margin-top: 40px;
  }

  .Home-3-list {
    margin-left: 0;
    max-width: 100%;
    button {
      margin: auto;
    }
  }
  .Home-gropod-img {
    height: auto;
    width: 50%;
    margin-bottom: 40px;
  }
}
