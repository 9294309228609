.Help-video {
    width: 100%;
    height: 100%;
    flex: 1;
}

.Help-contents {
  button {
    margin-bottom: 20px;
  }
  flex:1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Help-video-div {
   margin: 22px 0px;
   width: 100%;
   height: 315px;
}

.Support-video-div {
   margin: 20px 0px;
   width: 100%;
}

.Help-tutorial-link {
  cursor: pointer;
  margin: 20px 0;
}

.Help-category-title {
  margin-top:38px;
  margin-bottom: 8px;
}

.Help-text-area {
    flex:1;
    width: 100%;
    margin-bottom: 30px;
    padding-top: 15px;
}

.Help-form-div {
  margin-top: 20px;
}

//.Help-text-area::placeholder {
//  opacity: 0.5;
//  font-family: $robo;
//  font-size: 16px;
//  font-weight: 400;
//  letter-spacing: 0.8px;
//  color: #656565;/* Firefox */
//}
//
//.Help-text-area:-ms-input-placeholder{
//  opacity: 0.5;
//  font-family: $robo;
//  font-size: 16px;
//  font-weight: 400;
//  letter-spacing: 0.8px;
//  color: #656565;/* Firefox */
//}
