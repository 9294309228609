.Liveview-links-div {
  max-width: 400px;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  flex: 1;
  margin-left: 105px;
  border-radius: 30px;
  border: solid 1px rgba(78, 84, 92, 0.3);
  background-color: transparent;
  position: relative;
}

.Liveview-link-div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: $lime-green;
  background-color: white;
  cursor: pointer;
}

.Liveview-dashboard-btn {
  width: 100%;
}

.Liveview-link-div-active {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
  background-color: $lime-green;
  cursor: pointer;
}

.Liveview-share-button {

}

.Liveview-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 50px;
}

.Liveview-link-left {
  border-radius: 30px 0 0 30px;
}

.Liveview-link-right {
  border-radius:  0 30px 30px 0;
}

@media(max-width: 1350px) {
  .Liveview-button-row {
    flex-direction: column;
    align-items: center;
  }
  .Liveview-links-div {
    margin-left: 0;
    margin-bottom: 20px;
  }
  .Liveview-share-button {
  //  align-self:center;
    flex: 1;
  }
}

.Liveview-timelapse-content {
  text-align: center;
}
