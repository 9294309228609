.Order-item-div {
  margin-bottom: 45px;
}

.Order-row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px #dcdcdc solid;
  padding: 24px 0px;
  justify-content: space-between;

}

.Order-name {
  margin-bottom: 14px;
}

.Order-name-div {
  margin-left: 30px;
}

.Order-control-button {
  width: 160px;
}

.Order-top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px #dcdcdc solid;
//  margin-top: 30px;

}

.Order-controls {
  display: flex;
  flex-direction: column;
}

.Order-info-row {
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
}

.Order-button-buy {
    width: 160px;
    height: 40px;
    margin-bottom: 10px;
}

.Order-button-pdf {
    width: 160px;
    height: 30px;
}

.Order-product-image {
  height: 80px;
  width:80px;
  border: 1px #dcdcdc solid;
}

@media(max-width: 1150px) {
  .Order-controls {
    flex-direction: row;
    margin-top: 20px;
  }
  .Order-row {
    flex-direction: column;
  }
  .Order-button-buy {
    margin-right: 20px;
  }
}

@media(max-width: 950px) {
  .Order-controls {
    flex-direction: column;
  }
}

@media(max-width: 850px) {
  .Order-controls {
    flex-direction: row;
  }
}

@media(max-width: 500px) {
  .Order-controls {
    flex-direction: column;
  }
}
