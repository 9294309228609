.Seedpods-tabs-container {
  width: 100%;
  height: 60px;
  border-radius: 30px;
  background-color: transparent;
  position: relative;
  margin-bottom: 54px;
  cursor: pointer;
}

.Seedpods-navLinks-div {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-radius: 30px;
  border: solid 1px rgba(78, 84, 92, 0.3);
  background-color: transparent;
  position: relative;

}

.Seedpod-tab {
 flex: 1;
 height: 60px;
 display: flex;
 align-items: center;
 justify-content: center;
 padding: 0px 5px;
}

.Seedpods-tabs-background {
  width: 66.666%;
  height: 60px;
  border-radius: 30px;
  border: solid 1px rgba(78, 84, 92, 0.3);
  background-color: $lime-green;
  position: absolute;
  border-right-width: 0px;
}

.Seedpod-link {
  //background-color: yellow;
  flex: 1;
  height: 60px;
  display: flex;

  text-align: center;
  text-decoration: none;
  color: rgba(78, 84, 92, 0.3);

  font-weight: 500;
  font-family: $robo, sans-serif;
  font-size: calc(12px + .5vw);
  line-height: normal;
  &:hover {
    text-decoration: none;
  //  background-color: $account-hover-green;
    //color: rgba(78, 84, 92, 0.5);

  }
}

.Seedpods-active-tab {
  color: white;
}

.Seedpods-cart-widget {
  padding: 0 15px;
}

.SeedPods-cart-title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.Seedpods-cart-content {
  padding: 10px 0px;
  border-bottom: 1px #dcdcdc solid;
  border-top: 1px #dcdcdc solid;
}

.Seedpods-cart-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.trash-cat-seedpods {
  margin-left: 5px;
}

.SeedPods-cart-bottom-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;

  @media(min-width: 576px) {
    flex-direction: row;
    align-items: center;
  }
}

@media(max-width: 850px) {
  .ChooseAmount-package-div {
    flex-direction: column;
    min-width: 220px;
  }
  .Seedpods-tabs-container {
    margin-bottom: 25px;
  }
}

.Seedpods-cart-back-button {
  height: 52px;
}
