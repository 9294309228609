.form-select {
  &.size-lg {
    .react-select__control {
      height: 52px
    }
  }
  .react-select__control {
    .react-select__single-value--is-disabled {color: #5d5d5d};

    &.react-select__control--is-focused {
      border: 1px solid #e2eeb9;
      box-shadow: 0 0 0 0.2rem rgba(183, 213, 82, 0.25);
    }
  }

  &.is-invalid {
    .react-select__control {
      border: 1px solid $danger;

      &:hover {
        border: 1px solid $danger
      }

      &.react-select__control--is-focused {
        border: 1px solid $danger;
      }
    }
    .invalid-feedback {
      display: block;
    }
  }
}

.no-focus:focus {
  box-shadow: none;
}

.section-newsletter {
  background-color: #7f9e15;
  padding: 35px 0;
  .form-label {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
  }
  .subscribe-form {
    input {
      @extend .form-control;
      width: calc(100% - 140px);
      display: inline-block;
    }
    button {
      @extend .btn;
      @extend .btn-green;
      @extend .btn-green-small;
      padding: 0 15px;
      float: right;
    }
    .msg-alert > p {
      background: rgba($white, 0.7);
      margin-top: 10px;
      padding: 5px 10px;
      border-radius: 3px;
    }
  }
}
