.Account-card-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Account-cancel-button {
  margin-right: 10px;
}

.Account-view-section-title {
  margin-top: 20px;
}

.Account-view-item-p {
  margin: 10px 0px;
}

.Account-view-left-item {
  flex: 1;
}

.Account-view-right-item {
  flex: 1;
}

.Account-edit-buttons-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.Account-view-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 30px 0px;
  align-items: center;
}

.Account-view-top-div {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
    width: 97.5%;
}

.Account-shipping-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 20px;
}

.Account-address-checkbox-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Account-section-title {
  margin-bottom: 20px;
}

.Account-zipcode-input {
  width: 90%;
}

.Account-CVV-input {
  width: 90%;
}

.Account-title-div {
    text-align: center;
    margin-bottom: 40px;
}

.Account-nav {
    width: 300px;
    align-self: center;
    padding: 45px 6% 10px;
    height: 660px;
    border-radius: 15px;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    position: relative;
}

.Account-nav-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    border-bottom: 1px #dcdcdc solid;
    padding-bottom: 25px;
    margin-bottom: 20px;
    text-align: left;
}

.Account-double-row {
    display: flex;
   flex-direction: row;
   flex-wrap: wrap;
    margin: 50px 0px 60px 0px;
}

.Account-gropodId-text {
    text-align: center;
    //margin-bottom:
    height: 50px;
}

.Account-right-div {
    flex:1;
}

.Account-contents {
    flex:2;
}

.Account-active-tab {
      background-color: $account-link-green;
      box-shadow: inset 4px 0 0 0 $green;
}



.Account-link {
    text-decoration: none;
    height:45px;
    border-bottom: 1px #dcdcdc solid;
    &:hover {
      text-decoration: none;
      background-color: $account-hover-green;
    }
}

.Account-tab {
    background-color: transparent;
    height: 45px;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
}

.Account-tab > p {
    margin: 0px 0px 0px 40px ;

}

.Account-badge-image {
    height: 150px;
    width: 150px;
    margin-bottom: 30px;
  //  margin: 47px 80px 30px 80px;
}

.Account-tabs-container {
  display: flex;
  flex-direction: column;
}

.Account-form-div {
  align-self: center;
}

.Account-form-div > p {
  margin-bottom: 30px;
  margin: 20px 0 30px 0;
}

.Account-input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Account-credit-input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
//  flex-wrap: wrap;
}

.Account-state-dropdown {
  height: 52px;
  flex: 1;
  width: 190px;
  overflow-y: scroll;
}

.Account-margin-right {
  margin-right: 3vw;
}

.Account-ccImage {
  width: 63px;
  height: 40px;
}

.Account-view-ccImage {
  width: 63px;
  height: 40px;
  margin-left: 20px;
}

.Account-multi-input-div {
    flex-direction: row;
    display: flex;
    flex: 1;
    width: 100%;
    //max-width: 325px;
    //min-width: 270px;
}

.Account-standard-text-input {
//  min-width: 270px;
}

.Account-div-wrapper {
    flex: 1;
}

.Account-top-div {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
    width: 97.5%;
}

.Account-ccImage-div {
  flex:1;
//  min-width: 270px;
}

.Account-plus-button {
  font-size: 30px;
  color: $green;
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}

.Account-save-button {
  height: 40px;
}

.Header-tab > p {
    margin: 0px 10px 17px ;
    font-size: 15px;
    font-family: $robo-con, sans-serif;
    font-weight: 400;
}

.Text-account-link {
  font-family: $robo-con;
  font-size: 15px;
  font-weight: 500;
  color: $header-grey;

}


@media(max-width: 1150px) {
    .Account-top-div {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
    .Account-edit-buttons-row {
      margin-bottom: 10px;
    }
}

@media(max-width: 850px) {
  .Account-double-row {
    flex-direction: column;
  }
  .Account-input-row {
    flex-direction: row;
  }

  .Account-username {
      margin-right: 30px
  }

  .Account-tabs-container {
    margin-top: 20px;
  //  position: absolute;
  }

  .Account-nav-outer-div {
    height: 0;
  //  padding: 30px;
    width: 100%;
    margin-bottom: 150px;
    position: relative;
  }

  .Account-nav {
    height: auto;
    padding: 30px;
    width: 100%;
    margin-bottom: 60px;
    z-index: 1;
  }

  .Account-tab {
    height: 30px;
  }

  .Account-link {
    height: 30px;
    border: none;
  }

  .Account-tab > p {
    margin: 0 0px 0 10px;
  }

  .Account-badge-image {
      height: 40px;
      width: 40px;
      margin: 0;
      margin-right: 10px;
  }

  .Account-nav-header {
    margin: 0;
    padding: 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: none;
  }
  .Account-gropodId-text {
      height: 40px;
      text-align: left;
  }
}

@media(max-width: 675px) {
  .Account-nav {
  //  width: 150px;
  //  height: 0;
  }
  .Account-input-row {
    flex-direction: column;
  }

  .Account-view-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .Account-view-left-item {
    font-weight: 600;
    font-size: 14px;
  }

}
