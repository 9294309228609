.alert-container {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 1;
  .alert-box {
    border-radius: 0;
    box-shadow: 0px 1px 3px rgba(#929292, 0.2);
  }
}
