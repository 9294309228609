.Customize-title-div {
  margin-bottom: 43px;
}

.Customize-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex:1;
  justify-content: center;

  @media(min-width: 768px) {
    flex-direction: row;
  }
}

.Customize-left {
  margin-right: 40px;
  flex: 1;
  flex-grow: 1;
}

.Customize-seed-row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px #dcdcdc solid;
  padding: 20px 0 15px;

  .flex-wrap-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-left: 20px;

    .Customize-seed-description {
      flex-basis: 60%;
      flex-shrink: 1;
      min-width: 200px;
    }
  }

  @media(max-width: 576px) {
    flex-direction: column;

    .flex-wrap-row {
      flex-direction: column;
      margin-left: 0;
    }
  }
}

.Customize-seed-img {
  width: 90px;
  height: 99px;
}
.Customize-search-row {
  display: flex;
  flex-direction: row;
}

.Customize-top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Customize-top-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 7px;
}

.Customize-filled-text {
  align-self: flex-end;
}

.Customize-sort-row {
  display: flex;
  flex-direction: row;
}

.Customize-qty-dropdown {
  width: 110px;
}

.Customize-sort-dropdown {
  height: 26px;
  width: 160px;
  background-color: $lime-green;
  border-radius: 40px;
}

.Customize-search-input {
  width: 228px;
  height: 40px;
}

@media(max-width: 1225px) {
  .Customize-seed-img {
    margin-bottom: 20px;
  }
}

@media(max-width: 1100px) {
  .Customize-top-row {
    flex-direction: row;
  }
  .Customize-left {
    margin-right: 30px;
  }
}

@media(max-width: 675px) {
  .Customize-top-row {
    flex-direction: column;
  }
    .Customize-filled-text {
      align-self: flex-start;
    }
    .Customize-search-input {
      margin-bottom: 10px;
    }
      .Customize-sort-row {
        margin: 5px 0;
      }

  .Customize-img-div {
    display:flex;
    align-items: flex-end;
    margin-bottom: 20px;
  }
  .Customize-seed-img {
    margin-bottom: 0px;
    margin-right: 10px;
  }

}
