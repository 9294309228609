.video-container {
  position: relative;
  margin: 0;
  padding: 0;
  max-height: calc(100vh - 65px);
  max-height: -moz-calc(100vh - 65px);
  max-height: -webkit-calc(100vh - 65px);
  overflow: hidden;
  width: 100%;

  .background-image {
    display: block;
    background-image: url("./assets/hero-mobile.png");
    -webkit-background-size:cover;
    -moz-background-size:cover;
    -o-background-size:cover;
    background-size:cover;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    @media (min-width: 768px) { display: none; }
  }

  #video {
    background: #000;
    display: none;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    @media (min-width: 768px) { display: block; }
  }

  .video-content {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    padding: 20px;

    .video-header {
      font-size: 36px;
      line-height: 1.2;

      @media (min-width: 768px) { font-size: 48px; }

      @media (min-width: 992px) { font-size: 56px; }

      @media (min-width: 1200px) { font-size: 64px; }
    }

    .button-hero {
      border: 2px solid white;
      background: transparent;
      color: white;
      &:hover {
        background: white;
        color: black;
      }
    }
  }
}
