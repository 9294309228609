.Checkout-input-div {

}

.Checkout-form-div {
  flex: 1;
  padding: 0px 5.76%;
}

.Checkout-nomax {
  max-width: 40000px;
}

.Checkout-input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


@media(max-width: 675px) {
  .Checkout-input-row {
    flex-direction: column;
  }

}
