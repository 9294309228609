.form-control {
  height: 52px;
}

.Text-input {
  flex:1;
  width: 95%;
  height: 52px;
  border-radius: 3px;
  padding-left: 20px;
  border: solid 1px #dcdcdc;
  background-color: #ffffff;
}

.Text-input::placeholder {
  opacity: 0.5;
  font-family: $robo;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.8px;
  color: #656565;/* Firefox */
}

.Text-input:-ms-input-placeholder{
  opacity: 0.5;
  font-family: $robo;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.8px;
  color: #656565;/* Firefox */
}

.text-input-container {
    margin-bottom: 30px;
}
