.Accordion {
  width: 100%;
  overflow: hidden;

}

.Accordion-entry {
   //width: 605px;
   //height: 65px;
   padding: 20px 0;
   box-shadow: inset 0 -1px 0 0 rgba(147, 147, 147, 0.5);
   background-color: #ffffff;
   display: flex;
   align-items: center;
   justify-content: space-between;
   &:hover {
       background-color: #F8F8F8;
       cursor: pointer;
       font-weight: 400;
   }
}

.Accordion-down-arrow {
    margin-right: 13px;
    //animation: 2s App-logo-spin forwards;

  //  animation: App-logo-spin infinite 2s linear;

}

.Accordion-svg-div {
  margin-left: 5px;
}

.Accordion-animate {
  margin-right: 13px;

    animation: .3s App-logo-spin forwards;
//    animation: App-logo-spin infinite 2s linear;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes App-logo-despin {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes Accordian-height-transition {
  from {
    max-height: 0;
  }
  to {
    max-height: 600px;
  }
}

.Accordion-entry > p {
    margin-bottom: 0;
    margin-left: 10px;
     font-family: $open;
     font-size: 16px;
     font-weight: 500;
     color: #729404;
}

.Accordion-contents {
  overflow:hidden;
  background-color: $white;
  opacity: 1;
  max-height: 0;
  border-bottom: solid 1px #dcdcdc;
  align-items: left;
  //text-align: center;
  margin-top: -5px;
  animation: 1s Accordian-height-transition forwards;
  font-size: 40;
  padding-left: 24px;
  padding-bottom: 22px;
}

.Accordion-contents > ul {
  list-style: none;
  margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0px;
}


.accordion{
  display: inline-block;
  text-align: left;
  margin: 1%;
  width: 70%;
}

.accordion-content{
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
  background: #e5feff;
  overflow: hidden;
  // "height: 0" not work with css transitions
  max-height: 0;
}
