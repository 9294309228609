.Gropod-image {
    height: auto;
    margin-bottom: 37.7px;
    width: 90%;
    max-width: 378px;
}

.Double-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 50px 0px 60px 0px;
    justify-content: center;
    width: 100%;
}

.Double-row-2 {
  @extend .Double-row;
  margin: 32px 0px 32px 0px;
}

.Gropod-details {
  flex:1;
  min-width: 250px;
}

.Gropod-left-div {
  margin-right: 4.2%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Gropod-images-div {
  display: flex;
  flex-direction: row;
}

.Gropod-image-detail-div {
  height: 128px;
  width: 128px;
  border: solid 1px $button-border-grey;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  &:hover {
      border: solid 2px $button-border-grey;
      cursor: pointer;
  }
}

.Gropod-review-div {
  display: flex;
  flex-direction: row;
}

.Gropod-review-star {
  height: 16px;
  width: 16px;
}

.Gropod-image-detail {
  height: 79px;
  width: 79px;
}

.Gropod-details > p, span{
  margin: 10px 0 10px 0px;
}

.Gropod-review-div > p {
  margin: -3px 0px 21px 10px;
}

.Gropod-order-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 29px 0px 58px 0px;
}

.Gropod-dropdown {
  margin-right: 20px;
}

.Review-percent-breakdown-div {
  margin-bottom: 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Review-percent-breakdown-div > p {
  margin-bottom: 2px;
}

.Review-percent-bar {
  width: 120px;
  height: 16px;
  border-radius: 10px;
  margin: 0 10px 0 10px;
  background-color: $review-bar-grey;
}

.Review-percent-green {
  //width: 100px;
  height: 16px;
  position: absolute;
  border-radius: 10px;
  background-color: $lime-green;

}

.Review-key-div {
  //flex:1;
  margin-bottom: 30px;
  min-width: 230px;
  margin-right: 3.5%;
}

.Review-list {
  flex:2;
  min-width: 250px;
}

.Review-details-div {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.Review-user-info-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.Review-user-info-div > p {
  margin-bottom: 0;
}

.Review-user-image {
    height: 36px;
    width: 36px;
    border-radius: 18px;
    margin-right: 10px;
}

.Review-button-row {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
}

.Relatedproducts-img {
  width: 150px;
  height: auto;
}

.Relatedproducts-package-div {
  border-radius: 15px;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 28px 20px 28px;
  margin-right: 12.5px;
  margin-left: 12.5px;
  width: 245px;
  min-width: 245px;
  height: 420px;
}

.Relatedproducts-row {
  width: 100%;
  display: flex;
  overflow-x: scroll;
  height: 470px;
  align-items: center;
}

.Relatedproducts-title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Relatedproducts-package-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  align-items: flex-end;
}

.Relatedproducts-scrollButton {
  cursor: pointer;
}

.Relatedproducts-more-arrow {
  align-self: flex-end;
}

.Relatedproducts-package-upper-div {
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Relatedproducts-package-lower-div {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 50%;
  justify-content: space-between;
}

.Relatedproducts-section {
  width: 100%;
}

.Relatedproducts-star-div {
  margin-right: 15px;
}

.Gropod-tech-specs {
  width: 100%;
  h2 {
    padding-top: 25px;
  }
}


@media(max-width: 988px) {
    .Gropod-left-div {
        flex-direction: row;
        margin-right: 0px;
        margin-bottom: 37.7px;
        justify-content: center;
    }

    .Gropod-images-div {
        flex-direction: column;
    }
    .Gropod-image-detail-div {
        margin-right: 0;
        margin-bottom: 10px;
        height: auto;
        width: 100%;
    }
    .Gropod-image {
        margin-bottom: 0;
        margin-right: 20px;
    }
}

@media(max-width: 477px) {
    .Relatedproducts-package-div  {
      min-width: 200px;
    }
}
