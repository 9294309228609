.Dashboard-stat-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.Dashboard-stat-div {
  flex-direction: row;
  display: flex;
  flex:1;
  align-items: center;
  justify-content: center;
  margin: 0 0 30px 2vw;
}

.Dashboard-stat-image {
    //display: flex;
    height: 11vw;
    width: 11vw;
    max-height: 150px;
    max-width: 150px;
    min-width: 125px;
    min-height: 125px;
}

.Dashboad-stat-right-div {
    padding-left: 20px;
    min-width: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Dashboard-gropod-snapshot-btn {
  width: 100%;
  margin-left: 2vw;
}

@media(max-width: 850px) {
    .Dashboard-stat-div {
      margin-left: 0;
      flex-wrap: wrap;

      .Dashboad-stat-right-div {
        padding-left: 0;
        padding-top: 10px;
        text-align: center;
      }
    }
}
