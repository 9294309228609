
html, body, div, span, applet, object, iframe,
 h1, h2, h3, h4, h5, h6,
 p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center
 {
  margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}
