.ChooseAmount-contents {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: -25px;
}

.ChooseAmount-package-div {
  flex: 1;
  height: 510px;
  border-radius: 15px;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin: 0 0px 25px 0;
  padding: 0px 30px;
  min-width: 260px;
  max-width: 300px;
  margin-right: 25px;

  &:hover {
    cursor: pointer;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.2);
  }
}

.ChooseAmount-select-button {
   width: 160px;
   height: 52px;
}

.ChooseAmount-description-text {
  text-align: center;
}

.ChooseAmount-circle-div {
  height: 150px;
  width: 150px;
  border-radius: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $lime-green;
  margin-bottom: 30px;
  margin-top: 47px;
}

.ChooseAmount-package-upper-div {
  height: 272px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px #dcdcdc solid;
}

.ChooseAmount-package-lower-div {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 34px;
}

.ChooseAmount-savings-div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ChooseAmount-price-text {
  margin-bottom: 10px;
}

@media(max-width: 450px) {
  .ChooseAmount-package-div {
    flex-direction: column;
    min-width: 220px;
  }
}
