.flex-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1 1;
  flex-wrap: wrap;
}

.flex-col-grow {
  flex: 1 1;
  flex-grow: 1;
}

.flex-col-shrink {
  
}
