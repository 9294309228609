.Round-button-grey {
  border-radius: 26px;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 40px;
  padding: 0px 30px;
  color: $icon-grey;

  border: solid 1px rgba(78, 84, 92, 0.3);
  background-color: #ffffff;
  &:hover {
    border: solid 1px black;
  }
  &:disabled {
    border: solid 1px rgba(78, 84, 92, 0.2);
    color: $gray-400;
  }
}

.Round-button-text-grey {
  flex:1;
  font-family: $robo;
  font-size: 18px;
  font-weight: 700;
  margin:0;
}

.Round-button-text-grey-small {
  flex:1;
  font-family: $robo;
  font-size: 14px;
  font-weight: 500;
  color: #4e545c;
  margin:0;
}

.Round-button {
  background-color: $lime-green;
  border-radius: 26px;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 52px;
  padding: 0px 30px;
  color: white;
  &:hover {
    background-color: $button-hover-green;
    color: white;
  }

  &:disabled {
    background-color: rgba($button-hover-green, 0.4);
    color: white;
  }
}

.Round-button-text {
  flex:1;
  font-family: $robo;
  font-size: 18px;
  font-weight: 700;
  margin:0;
}

.Round-button-white {
  background-color: $white;
  border-radius: 26px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 10px 30px;
  color: $primary;
  &:hover {
    color: darken($primary, 15%);
  }
}

.Round-button-text-small {
  flex:1;
  font-family: $robo;
  font-size: 18px;
  font-weight: 500;
}

p label{
  margin:0;
}
.Button-squared  {
  background-color: $lime-green;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 52px;
  padding: 0 20px;
  color: $button-text-green;
  &:hover {
    background-color: $button-hover-green;
  }
}

.Button-squared-white  {
  @extend .Button-squared;
  background-color: $white;
  color: $dark;
  &:hover {
    background-color: $white;
    border-color: $black;
  }
}

.Button-text-square {
  flex:1;
  font-family: $robo;
  font-size: 18px;
  font-weight: 700;
  margin:0;
}

.Star-button {
  display: inline-block;

  &:hover {cursor: pointer}
}

.btn {
  display: inline-block;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;

  &.btn-green {
    line-height: 50px;
    padding: 0 35px;
    background-color: #b7d552;
    color: #546810;
    &:hover {
      background-color: #546810;
      color: #b7d552;
    }
  }
  &.btn-green-small {
    background-color: #b7d552;
  }
}
