.header-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 20px 0;
}

.z-index-0 {
  z-index: 0 !important;
}

.footer-panel {
  position: fixed;
  bottom: 0;
  height: auto;
  background: #cddf92;
  padding: 50px;
  width: 100%;
}

.btn-close-container {
  position: absolute;
  top: 5px;
  right: 5px;
  .btn-close {
    color: #729403;
    font-size: 24px;
    &:hover {
      text-decoration: none;
      color: darken(#729403, 15%)
    }
  }
}

.text-box-truncate {
  max-height: 50px;
  overflow: hidden;
}

a {
  &:hover {
    text-decoration: none;
  }
}

.second {
  position: relative;
  width: 1000px;
}

.diagonal-ribbon {
  height: 35px;
  width: 110px;
  position: absolute;
  top: 36px;
  left: -2px;
  text-align: center;
  padding-top: 3px;
  color: #FFF;
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.diagonal-ribbon:before {
  box-sizing: border-box;
  border-style: solid;
  border-color: transparent;
  border-width: 37px;
  border-left-width: 38px;
  border-right-width: 37px;
  content: "";
  display: block;
  left: -40px;
  position: absolute;
  width: 172%;
  border-top-width: 0px;
  top: -1px;
  z-index: -1;
}

.ribbon-pink:before {
  border-bottom-color: #ED63A8;
};

.ribbon-blue:before {
  border-bottom-color: #3CC5F4;
};

.alert-enter {
  margin-top: -50px;
}
.alert-enter-active {
  margin-top: 0;
  transition: margin-top 500ms;
}
.alert-exit {
  margin-top: 0;
}
.alert-exit-active {
  margin-top: -50px;
  transition: margin-top 500ms;
}

.seed-enter {
  opacity: 0;
}
.seed-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.seed-exit {
  opacity: 1;
}
.seed-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}
