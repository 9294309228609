.Cart-chart {
  width: 100%;
}

.Cart-chart > p {
      font-size: 16px;
      font-family: $open;
      line-height: 24px;
      color: $icon-grey;
}

.Cart-title-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px #dcdcdc solid;
  padding-bottom: 5px;
  margin-top: 50px;
}

.Cart-final-row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px #dcdcdc solid;
  margin-top: 30px;
}

.Cart-final-row-shopping {
  border-bottom: none;
}

.Cart-row-item {
  flex: 1;
  padding-right: 20px;
  //border: 1px red solid;
}

.Cart-row-item-last {
  flex: 1;
  padding-right: 0px;
  //border: 1px red solid;
}

.Cart-checkout-order-button {
  width: 180px;
  align-self: flex-end;
  margin-bottom: 30px;
}

.Cart-billing-row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px #dcdcdc solid;
  padding: 20px 0px;
}

.Cart-billing-row-title {
  flex:1;
  min-width: 170px;
}

.Cart-billing-row-icon {
  margin-right: 23px;
  color: $lime-green;
  cursor: pointer;
}

.Cart-billing-row-info {
  flex:4;
}


.Cart-row-item-2 {
  flex: 2;
  padding-right: 20px;
}

.Cart-row-item-3 {
  flex: 3;
  padding-right: 20px;
}

.Cart-row-row-2 {
  flex: 2;
  padding-right: 20px;
  display: flex;
  //border: 1px red solid;
}

.Cart-row-row-3 {
  flex: 3;
  padding-right: 20px;
  display: flex;
  justify-content: flex-start;
  //border: 1px red solid;
}

.Cart-row-item-min {
  flex: 1;
  padding-right: 20px;
}

.Cart-row-item-min-div {
    min-width: 120px;
}

.Cart-item-img {
  height: 120px;
  width: 120px;
}

.Cart-item-row {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  border-bottom: 1px #dcdcdc solid;
  align-items: center;
}

.Cart-quantity-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Cart-qty-dropdown {
  align-self: center;
  width: 50px;
}

.Cart-qty-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 63px;
  margin: 0px 10px;
  border-radius: 3px;
  border: solid 1px #dcdcdc;
  background-color: #ffffff;
}

.Cart-qty-plus-div {
  cursor: pointer;
}

.Cart-qty-plus {
  font-weight: 700;
  color: $green;
  font-size: 18px;
}

.Cart-qty-text {

}

.Cart-small-item {
  flex: 1;
}

.Cart-small-container-row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  flex-wrap: wrap;
}

.Cart-small-container-left {
  flex: 1;
  margin-right: 20px;
}

.Cart-small-container-right {
  flex: 3;
  min-width: 300px;
}

.Cart-small-row {
  display: flex;
  align-items: center;
  border-bottom: solid 1px #dcdcdc;
  padding: 10px 0;
}

@media(max-width: 850px) {
  .Cart-final-row {
    flex-direction: column;
  }

}

@media(max-width: 992px) {
  .Cart-billing-row {
    flex-direction: column;

    .Cart-billing-row-info {
      padding-left: 40px;
    }
  }
}

@media(max-width: 500px) {
  .Cart-small-container-row {
    flex-direction: column;
  }
  .Cart-checkout-order-button {
    align-self: center;
  }
}
