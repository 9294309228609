
// p {
//   margin-bottom: 20px;
// }
.About-iframe {
  width: 100%;
  height: 41vw;
  flex: 1;
  margin: 0px 0 40px 0;
}

.About-row {
  display:flex;
  flex-direction: row;
  margin: 75px 0px;
  align-items: center
}

.About-bio-img-div{
  flex:1;
//  padding-right: 5%;
}

.About-bio-img {
  width:90%;
  height: auto;
}

.About-bio-div {
  flex:1;
}

.About-p-margin > p {
  margin-bottom: 20px;
}



@media(max-width: 1000px) {
  .About-row {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 5%;
  }
  .About-bio-img {
    width:100%;
  }
  .About-bio-img-div {
    margin-bottom: 30px;
  }
}
