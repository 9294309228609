.Subscription-item-div {
  border-bottom: 1px #dcdcdc solid;
  padding: 30px 0px;
}

.Subscription-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.Subscription-info {
    flex:2;
  //  min-width: 260px;
}

.Subscription-secondary-title {
    margin-top: 30px;
}

.Subscription-controls {
  display: flex;
  flex-direction: row;
  //flex-wrap: wrap;
  flex: 2;
  justify-content: space-between;
}

.Subscription-skip-shipment-text {
  margin-top: 19px;
}

.Subscription-control-right {
  width: 150px;
}

.Subscription-skip-button {
  min-width: 222px;
}

.Subscription-control-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1;
}

.SeedPod-Selection {
  .Text-subtitle {
    display: none;
  }
}

.Subscription-margin-bottom {
  margin-bottom: 27px;
}

.Subscription-margin-bottom-cancel {
  margin-bottom: 27px;
  margin-top: 8px;
}

@media(max-width: 1350px) {
  .Subscription-controls {
    flex-direction: column;
  }
  .Subscription-margin-bottom {
    margin-bottom: 10px;
  }
  .Subscription-margin-bottom-cancel {
    margin-bottom: 10px;
  }

  .Subscription-control {
    margin-bottom: 10px;
  }
}

@media(max-width: 1150px) {
  .Subscription-row {
    flex-direction: column;
  }
  .Subscription-control-col {
    align-items: flex-start;
  }
}

@media(max-width: 850px) {
  .Subscription-row {
    flex-direction: row;
  }
  .Subscription-control-col {
    align-items: flex-end;
  }
}

@media(max-width: 675px) {
  .Subscription-row {
    flex-direction: column;
  }
  .Subscription-control-col {
    align-items: flex-start;
  }
}
