.StripeElement {
  @extend .form-control;
  height: 52px;
  padding: 15px;
}

.StripeElement--focus {
  border-color: #e2eeb9;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(183, 213, 82, 0.25);
}

.StripeElement--invalid {
  border: 1px solid red;
}
