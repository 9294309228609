.Select-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //flex: 1;
  width: 100%;
}
.Select-contents {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //flex: 1;
  width: 100%;
}

.SelectSeeds-navLinks-div {
  width: 90%;
  max-width: 500px;
  margin-right: 0;
}

.Select-tabs-container {
  display:flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  flex: 1;
}

.Select-Active-tab {
  color: white;
  background-color: $lime-green;
  border-radius: 30px;
  height: 60px;

}

.Select-tab-left {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.Select-tab-right {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.Select-package-div {
  flex: 1;
  height: 550px;
  border-radius: 15px;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin: 0 auto 30px ;
  padding: 0px 30px;
  min-width: 260px;
  max-width: 309px;
}

.Select-Link {
  color: $green;
}

.Select-tab {
  width: 50%;

  max-width: 250px;
}

.Select-lower-div {
  padding-top: 24px;
}

@media(max-width: 850px) {

  .Select-tabs-container {
    border-radius:0;
  }
  
  .SelectSeeds-navLinks-div {
    height: 40px;
  }

  .Select-tab {
    height:40px;
    border-radius: 0;

  }
  .Select-Link {
    height:40px;
  }


}
