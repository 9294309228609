.Products-div {
  width: 280px;
  max-width: 280px;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 25px;
  margin: 25px 25px 25px 0;
  border-radius: 15px;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.1);
  
  &:hover {
    border-radius: 15px;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
}

.video-holder {
  text-align: center;
}

.Products-main {
  margin-top: 62px;
}

.Products-image {
  height: auto;
  width: 200px;
}

.Products-image-div {
  width: 100%;
  height: 330px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.Products-squarebutton {
  height: 52px;
  margin-top: auto;
}

.Products-price {
  margin: 10px 0px;
}

.Products-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gropod-product {
  margin-top: -60px;

  h2 {
    padding-top:100px;
    margin-bottom: 8px;
  }

  .half-bg {
    p {
      margin-bottom: 50px;
    }
  }
}

.section-features {
  img {
    max-width: 50vw;
    max-height: 50vh;
  }
  .feature-point {
    border-bottom: 1px solid #ededed;
    padding-bottom: 25px;
    padding-top: 20px;
  }
  .feature-point label {
    font-size: 100%;
    font-family: 'Open Sans', sans-serif;
    line-height: 30px;
    margin: 0;
  }
  .feature-point label:after {
    content: url("./assets/point.png");
    float: left;
    margin-right: 7px;
  }
  .feature-point p {
    font-size: 100%;
    color: #656565;
    margin: 0;
  }
  .feature-point.last {
    border: none;
  }
}
