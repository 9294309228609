.Seed-checkout-order-button {
  width: 180px;
}

.Seed-checkout-left {
    margin-right: 40px;
    flex: 1;
}

@media(max-width: 850px) {
  .Seed-checkout-left {
      margin-right: 0px;
  }
}
