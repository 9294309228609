@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700');

$lime-green: #B7D552;
$green: #729404;
$button-text-green: #546810;
$button-hover-green:rgb(153,189,53);
$white: #FFF;
$footer-grey: #656565;
$content-grey: #4a4a4a;
$header-grey: #58585a;
$icon-grey: #4e545c;
$button-border-grey: #c8c8c8;
$review-bar-grey: #e7e8e9;
$account-link-green: rgba(219, 235, 166, 0.5);
$account-hover-green: rgba(219, 235, 166, 0.2);

$robo-con: 'Roboto Condensed';
$robo: 'Roboto';
$open: 'Open Sans';

$primary: $lime-green;
$success: $lime-green;
