.PreSelect-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex:1;
  justify-content: center;

  @media(min-width: 768px) {
    flex-direction: row;
  }
}

.PreSelect-left {
  margin-right: 40px;
  flex: 1;
  flex-grow: 1;
}

.PreSelect-right {
// flex:1
}

.Preselect-package-div {
  margin-bottom: 40px;
}

.Preselect-package-div-left {
  margin-bottom: 10px;
}

.Preselect-package-row {
  display: flex;
  flex-direction: row;
}

.Preselect-img {
  height: 180px;
  width: 180px;
  border-radius: 15px;
  margin-right: 30px;
}

.Preselect-bottom-row {
  display: flex;
  flex-direction: row;
  align-items: center;
//  justify-content: space-between;
}

.Preselect-bottom-row-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Preselect-bottom-row-div-1 {
  margin-top: 10px;

}

.Preselect-bottom-row-div-2 {
  margin-top: 10px;
}

.Preselect-clock-icon {
  color: rgba(88, 88, 90, 0.5);
  font-size: 12px;
}

.Preselect-harvest-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Preselect-harvest-row > p {
  margin-bottom: 0;
}

.Preselect-squarebutton {
  margin-right: 15px;
}

.Preselect-included {
  margin: 10px 0;
}

.Preselect-dropdown {
  margin: 0 10px;
}

@media(max-width: 1225px) {
  .Preselect-bottom-row {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

@media(max-width: 1100px) {
  .PreSelect-left {
    margin-right: 30px;
  }
}

@media(max-width: 576px) {
  .Preselect-package-row {
    flex-direction: column;
  }
}
